import React, { useContext, useState } from "react";
import "./Login.css";

import { NavLink, useNavigate } from "react-router-dom";
import Register from "../register/Register";
import Axios from "axios";
import { UserContext } from "../../utils/userContext";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import SEO from "../../components/SEO";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [username, setUsername] = useState("");
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);

    if (email === "" || password === "") {
      toast.error("Please fill out all fields!");
      setLoading(false);
      return;
    }

    if (!email.includes("@")) {
      toast.error("Please enter a valid email address!");
      setLoading(false);
      return;
    }

    try {
      // Send login request to the server
      const loginResp = await Axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/auth/login",
        {
          email,
          password,
        }
      );

      if (loginResp.status === 200) {
        setUser(loginResp.data.user);
        console.log(loginResp.data)
        Cookies.set("token", loginResp.data.token);
        navigate("/main/dashboard");
      } else {
        toast.error(loginResp.data.message || "Login failed!");
      }
    } catch (err) {
      toast.error("Login attempt failed. Please try again later!");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login">
        <SEO dynamicTitle="Coderella | Login" />
        <h1>Login</h1>

        <div className="form_container">
          <form className="main_form">
            {/* <input
              type="text"
              placeholder="Username*"
              required
              onChange={(e) => setUsername(e.target.value)}
            /> */}
            <input
              type="email"
              placeholder="Email*"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password*"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </form>
          <button onClick={handleLogin}>
            {loading ? "Please Wait" : "Log In"}
          </button>
          <div className="desc">
            <p>Not registered yet?</p>
            <p>
              <NavLink to="/register" key={<Register />} className="link">
                Create account*
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ dynamicTitle }) => {
  return (
    <Helmet>
      <title>{dynamicTitle}</title>
      <meta name="author" content="Malay Damani" />
      <meta name="description" content="One stop platform empowering women to excel in their tech careers through coding education, internships, mentorship, and hackathons." />
    <meta name="keywords" content="women in tech, coding for women, tech career, women empowerment, mentorship, coding bootcamps, tech education, internships, hackathons" />

      {/* Open Graph (Facebook) : */}
      <meta property="og:title" content="Coderella - Empowering Women in Tech" />
    <meta property="og:description" content="One stop platform empowering women to excel in their tech careers through coding education, internships, mentorship, and hackathons." />
    <meta property="og:image" content="https://example.com/path/to/your/image.jpg" />
    <meta property="og:url" content="https://coderella.whiskerweb.studio/" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Coderella" />
    <meta property="og:locale" content="en_US" />

      {/* Twitter : */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content="https://coderella.whiskerweb.studio/" />

      <meta name="twitter:title" content="Coderella - Empowering Women in Tech" />
      <meta
        name="twitter:description"
        content="One stop platform empowering women to excel in their tech careers through coding education, internships, mentorship, and hackathons."
      />
      <meta
        name="twitter:image"
        content="https://example.com/path/to/your/image.jpg"
      />
      <meta name="robots" content="Index" />
      <link rel="manifest" href="/manifest.json" />

      {/* Favicon : */}
      <link rel="apple-touch-icon" sizes="120x120" href="./favicon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="./favicon.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="./favicon.png" />



      {/* Structured Data (JSON-LD) */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Coderella",
            "url": "https://coderella.whiskerweb.studio/",
            "logo": "https://example.com/path/to/your/logo.png",
            "sameAs": [
              "https://www.facebook.com/Coderella",
              "https://www.twitter.com/Coderella",
              "https://www.instagram.com/Coderella",
              "https://www.linkedin.com/company/coderella"
            ],
            "description": "One stop platform empowering women to excel in their tech careers through coding education, internships, mentorship, and hackathons.",
            "founder": {
              "@type": "Person",
              "name": "Malay Damani"
            },
            "foundingDate": "2023",
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "Customer Support",
              "email": "damanimalay@gmail.com"
            },
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Your City",
              "addressRegion": "Your State",
              "postalCode": "Your Postal Code",
              "addressCountry": "Your Country"
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO;
